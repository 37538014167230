<template>
  <div class="reserved-area-page">
    <div class="container">
      <div class="d-xl-flex">
        <div class="reserved-area-page-column">
          <reservedAreaMenu/>
        </div>
        <div class="reserved-area-page-column">
          <p class="section-title billing-title d-none d-xl-block">Dados de faturação</p>
          <FormulateForm name="billing-form" class="billing-form" v-model="values" :errors="errors" @submit="billingHandler">
            <div class="reserved-area-form-group-wrapper">
              <div class="reserved-area-form-group">
                <div class="row">
                  <div class="reserved-area-form-column col-12 col-lg-6 col-xl-12 col-xxl-6">
                    <div class="form-field">
                      <FormulateInput type="text" name="name" v-model="firstName" validation="required" placeholder="Insira o seu primeiro nome" label="Primeiro Nome" />
                    </div>
                  </div>
                  <div class="reserved-area-form-column col-12 col-lg-6 col-xl-12 col-xxl-6">
                    <div class="form-field">
                      <FormulateInput type="text" name="last_name" v-model="lastName" validation="required" placeholder="Insira o seu último nome" label="Último Nome" />
                    </div>
                  </div>
                  <div class="reserved-area-form-column col-12 col-lg-12 col-xl-12 col-xxl-12">
                    <div class="form-field">
                      <FormulateInput type="textarea" name="address" v-model="billingAddress" validation="required" placeholder="Insira a sua morada" label="Morada" />
                    </div>
                  </div>
                  <div class="reserved-area-form-column col-12 col-lg-6 col-xl-12 col-xxl-6">
                    <div class="form-field">
                      <FormulateInput type="text" name="zipCode" v-model="zipCode" validation="required" placeholder="Insira o seu código-postal" label="Código-Postal" />
                    </div>
                  </div>
                  <div class="reserved-area-form-column col-12 col-lg-6 col-xl-12 col-xxl-6">
                    <div class="form-field">
                      <FormulateInput type="text" name="city" v-model="city" validation="required" placeholder="Insira a sua cidade" label="Cidade" />
                    </div>
                  </div>
                  <div class="reserved-area-form-column col-12 col-lg-6 col-xl-12 col-xxl-6">
                    <div class="form-field">
                      <FormulateInput type="select" name="country" v-model="country" validation="required" label="País" :options="this.$root.countries" />
                    </div>
                  </div>
                  <div class="reserved-area-form-column col-12 col-lg-6 col-xl-12 col-xxl-6">
                    <div class="form-field">
                      <FormulateInput type="text" name="vat" v-model="nif" validation="required" placeholder="Insira o seu nif" label="NIF" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-message-response-wrapper message-error" v-if="showMessage && !submitted">
              <p>{{ formResponseMessage }}</p>
            </div>
            <div class="reserved-area-form-btn-wrapper d-flex justify-content-center align-items-center">
              <button type="submit" class="reserved-area-form-btn generic-btn red-gradient ">Guardar</button>
            </div>
          </FormulateForm>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import reservedAreaMenu from "@/components/reserved-area/reserved-area-menu.vue";
import axios from "axios";
import qs from "qs";

export default {
  name: "reserved-area-billing-data",
  components: {
    reservedAreaMenu,
  },
  metaInfo () {
    return {
      title: this.$root.translations.definicoes,
    }
  },
  data() {
    return {
      values: {},
      errors: {},
      submitted: false,
      firstName: '',
      lastName: '',
      billingAddress: '',
      zipCode: '',
      city: '',
      country: '',
      nif: '',
      showMessage: false,
      formResponseMessage: '',
    };
  },
  methods: {
    billingHandler() {
      let authKey = window.$cookies.get("authKey");

      const requestBody = {
        updateType: 'billingInfo',
        firstName: this.firstName,
        lastName: this.lastName,
        address: this.billingAddress,
        zipCode: this.zipCode,
        city: this.city,
        country: this.country,
        nif: this.nif,
      };

      axios
          .post(process.env.VUE_APP_API_URL + 'customer', qs.stringify(requestBody),{
            headers: {
              Authorization: 'Bearer ' + authKey
            }
          })
          .then((response) => {
              this.$toast.success("Enviado com sucesso!", {position: 'bottom-right', hideProgressBar: true});

          })
          .catch((e) => {
            if (e.response.data.errors) {
              this.errors = e.response.data.errors;
            } else {
              this.showMessage = true;
              this.formResponseMessage = 'An error occurred.';
            }
          });
    },
    setData() {
      if (Object.keys(this.$root.userInfo.billing_info).length) {
        this.firstName = this.$root.userInfo.billing_info.name;
        this.lastName = this.$root.userInfo.billing_info.last_name;
        this.country = this.$root.userInfo.billing_info.country_id;
        this.city = this.$root.userInfo.billing_info.city;
        this.phone = this.$root.userInfo.billing_info.phone;
        this.zipCode = this.$root.userInfo.billing_info.postal_code;
        this.billingAddress = this.$root.userInfo.billing_info.address;
        this.nif = this.$root.userInfo.billing_info.vat;
      }
    }
  },
  async created() {
    await this.$root.getCustomer();
    this.setData();
    this.$root.pageTitle = this.$root.translations.definicoes;
  }
}
</script>